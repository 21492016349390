<template>
  <v-dialog v-model="data.dialog" persistent max-width="600px">
    <v-card v-if="loading" :color="isDelete ? 'error' : 'primary'" dark>
      <v-card-text>
        <span class="font-weight-bold text-white">{{ $t('loading') }}</span>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading">
      <v-card-title
        :class="{'form-danger': isDelete, 'form-info': isUpdate || isNotification || isRenew, 'form-primary': isCreate}"
      >
        <span v-if="isNotification" class="">ارسال اشعار</span>
        <span v-if="isRenew" class="">تجديد العضو</span>
        <span v-if="isCreate" class="">{{ $('create') }}</span>
        <span v-if="isUpdate" class="">{{ $('update') }}</span>
        <span v-if="isDelete" class="">{{ $('delete') }}</span>
      </v-card-title>
      <v-card-text>
        <h2 v-if="isDelete && user" class="my-3">
          {{ $('delete_msg', [user.name]) }}
        </h2>
        <v-form
          v-if="!isDelete"
          ref="form"
          v-model="valid"
        >
          <v-container v-if="isCreate || isUpdate">
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.name"
                  v-max-length="200"
                  :label="$('name')"
                  :rules="rules.name"
                  autocomplete="new-password"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.username"
                  v-max-length="200"
                  label="اسم المستخدم"
                  :rules="rules.username"
                  autocomplete="new-password"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.email"
                  v-max-length="200"
                  label="البريد الالكتروني"
                  :rules="rules.email"
                  autocomplete="new-password"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="form.phone"
                  v-max-length="200"
                  label="رقم الهاتف"
                  autocomplete="new-password"
                  required
                  :rules="rules.phone"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.password"
                  v-max-length="200"
                  :label="$('password')"
                  autocomplete="new-password"
                  :rules="rules.password"
                  :append-icon="hidePassword ? 'visibility' : 'visibility_off'"
                  :type="hidePassword ? 'password' : 'text'"
                  required
                  @click:append="togglePassword()"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.description"
                  v-max-length="200"
                  label="الوصف"
                  :rules="rules.description"
                  autocomplete="new-password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-container v-if="isNotification">
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.title"
                  v-max-length="200"
                  label="العنوان"
                  :rules="rules.title"
                  autocomplete="new-password"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
              >
                <v-text-field
                  v-model="form.message"
                  v-max-length="200"
                  label="النص"
                  :rules="rules.message"
                  autocomplete="new-password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="isRenew || isCreate">
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-select
                  v-model="form.added_days"
                  label="مدة التفعيل"
                  :items="renews"
                  item-text="item"
                  item-value="value"
                  :rules="rules.added_days"
                  required
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light"
          outlined
          @click="dialogModal(false)"
        >
          {{ $t('form.cancel') }}
        </v-btn>
        <v-btn
          v-if="isCreate"
          color="primary"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.create') }}
        </v-btn>
        <v-btn
          v-if="isUpdate"
          color="info"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.save') }}
        </v-btn>
        <v-btn
          v-if="isNotification"
          color="info"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          ارسال
        </v-btn>
        <v-btn
          v-if="isRenew"
          color="info"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          تفعيل
        </v-btn>
        <v-btn
          v-if="isDelete"
          color="error"
          :disabled="submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import toast from '@/store/toast'

export default {
  name: 'UserDialog',
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        id: null,
        type: 'create',
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:table'],
  data: () => ({
    loading: true,
    submitLoading: false,
    valid: false,
    hidePassword: true,
    user: null,
    renews: [
      {
        item: 'يوم واحد',
        value: '1',
      },
      {
        item: 'يومين',
        value: '2',
      },
      {
        item: '7 ايام',
        value: '7',
      },
      {
        item: 'شهر',
        value: '30',
      },
      {
        item: 'شهرين',
        value: '60',
      },
      {
        item: '3 اشهر',
        value: '90',
      },
      {
        item: '6 اشهر',
        value: '182',
      },
      {
        item: 'سنة',
        value: '365',
      },
      {
        item: 'تعطيل',
        value: '0',
      },
    ],
    form: {
      id: '',
      name: '',
      email: '',
      username: '',
      password: '',
      description: '',
      phone: '',
      title: '',
      message: '',
      added_days: '',
    },
  }),
  computed: {
    isRenew() {
      return this.data.type === 'renew'
    },
    isNotification() {
      return this.data.type === 'notification'
    },
    isCreate() {
      return this.data.type === 'create'
    },
    isUpdate() {
      return this.data.type === 'update'
    },
    isDelete() {
      return this.data.type === 'delete'
    },
    rules() {
      const rules = {};
      rules.name = [
        v => !!v || this.$t('form.required', ['الاسم']),
      ];
      rules.phone = [
        v => !!v || this.$t('form.required', ['رقم الهاتف']),
      ];
      rules.username = [
        v => !!v || this.$t('form.required', ['اسم المستخدم']),
      ];
      rules.description = [
        v => !!v || this.$t('form.required', ['الوصف']),
      ];
      rules.email = [
        v => !!v || this.$t('form.required', ['البريد الالكتروني']),
        v => /.+@.+\..+/.test(v) || this.$t('form.pattern', ['البريد الالكتروني']),
      ];
      if (this.isCreate) {
        rules.password = [
          v => !!v || this.$t('form.required', ['كلمة المرور']),
        ];
      }
      if (this.isRenew || this.isCreate) {
        rules.added_days = [
          v => !!v || this.$t('form.required', ['مدة التفعيل']),
        ];
      }
      if (this.isNotification) {
        rules.title = [
          v => !!v || this.$t('form.required', ['عنوان الاشعار']),
        ];
        rules.message = [
          v => !!v || this.$t('form.required', ['رسالة الاشعار']),
        ];
      }

      return rules;
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(val) {
        try {
          this.loading = true;
          if (val.data) {
            this.user = val.data;
            this.form.id = val.data.id;
            this.form.name = val.data.name;
            this.form.username = val.data.username;
            this.form.email = val.data.email;
            this.form.description = val.data.description;
            this.form.phone = val.data.phone;
            this.form.password = '';
          }
          if (!val.dialog) {
            this.resetForm();
          }
        } catch (error) {
          //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
        } finally {
          this.loading = false;
        }
      },
    },
  },
  methods: {
    async submit() {
      try {
        const formData = new FormData();
        formData.append('id', this.form.id);
        this.submitLoading = true;
        if (this.isDelete) {
          await axios.post('/user/delete', formData);
          await toast.dispatch('success', this.$t('form.success.delete'))
          this.$emit('update:table')
          this.dialogModal(false)

          return;
        }
        if (this.$refs.form.validate() === false) {
          return;
        }
        if (this.isNotification) {
          formData.append('title', this.form.title);
          formData.append('message', this.form.message);
          await axios.post('/user/sendFCMNotification', formData);
          await toast.dispatch('success', 'تم ارسال اشعار بنجاح')

          return;
        }
        if (this.isRenew) {
          formData.append('added_days', this.form.added_days);
          await axios.post('/user/updateExpire', formData);
          this.$emit('update:table')
          await toast.dispatch('success', 'تم التفعيل بنجاح')

          return;
        }
        formData.append('email', this.form.email);
        formData.append('name', this.form.name);
        formData.append('username', this.form.username);
        formData.append('description', this.form.description);
        formData.append('password', this.form.password);
        formData.append('phone', this.form.phone);
        if (this.isCreate) {
          formData.append('added_days', this.form.added_days);
          formData.append('is_manager', 0);
          await axios.post('/user/create', formData);
          await toast.dispatch('success', this.$t('form.success.create'))
        } else if (this.isUpdate) {
          await axios.post('/user/update', formData);
          await toast.dispatch('success', this.$t('form.success.update'))
        }
        this.$emit('update:table')
        this.dialogModal(false)
      } catch (error) {
        //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      } finally {
        this.submitLoading = false;
      }
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.form = {
        id: '',
        name: '',
        email: '',
        username: '',
        password: '',
        description: '',
        phone: '',
      };
      this.imagePreview = null;
      this.user = null;
    },
    togglePassword() {
      this.hidePassword = !this.hidePassword;
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters);
    },
  },
}
</script>

<style scoped>

</style>
